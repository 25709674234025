<template>
  <div id="terms" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="terms" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          terms.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Session Title" v-slot="props">
          {{ props.row.sessionTime }}
          {{ props.row.sessionYear }}
        </b-table-column>

        <b-table-column label="Begin Date" v-slot="props">
          {{ props.row.beginDate }}
        </b-table-column>

        <b-table-column label="End Date" v-slot="props">
          {{ props.row.endDate }}
        </b-table-column>

        <b-table-column label="Open Days" v-slot="props">
          {{ props.row.openDays }}
        </b-table-column>

        <b-table-column label="Next Term Begins" v-slot="props">
          {{
            props.row.nextTermBegins ? props.row.nextTermBegins : 'NA'
          }}</b-table-column
        >

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'terms',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Terms',
      isActive: false,
      terms: [],
      id: null,
      session_times: [
        { value: 'First Term', text: 'First Term' },
        { value: 'Second Term', text: 'Second Term' },
        { value: 'Third Term', text: 'Third Term' },
      ],
    }
  },
  methods: {
    initializeInfo(term) {
      // this.$router.push(`/school/${this.schoolId}/term_info/${term.id}`)
    },
    initializeUpdate(term) {
      this.$router.push(`/school/${this.schoolId}/term/${term.id}`)
    },
    initializeRemove(term) {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Not Allowed.',
        position: 'is-top',
        type: 'is-danger',
        hasIcon: true,
      })
      return
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteTerm($id: Int!) {
              deleteTerm(input: { id: $id }) {
                term {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteTerm.errors,
            'Successfully deleted.'
          )
          this.$apollo.queries.terms.refetch()
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      { name: 'New', route: `/school/${this.schoolId}/term/new` },
    ])

    this.$apollo.addSmartQuery('terms', {
      query: gql`
        query TermsQuery($schoolId: Int!) {
          terms(schoolId: $schoolId) {
            id
            sessionTime
            sessionYear
            beginDate
            endDate
            openDays
            nextTermBegins
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.terms.refetch()
  },
}
</script>

<style lang="scss" scoped>
#terms {
  text-align: left;
}
</style>
